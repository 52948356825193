import * as React from 'react'
import { useQuery } from 'react-query'
import { find } from 'lodash'
import { layersList } from '../adapters/layers'
import AutocompleteFamily from '../components/map/dialog/crops/AutocompleteFamily'

const LayersContext = React.createContext()

export function layerReducer(state, action) {
	switch (action.type) {
		case 'SEED_LAYER': {
			return action.payload
		}
		case 'TOGGLE_LAYER_VISIBILITY': {
			return state.map((layer) => {
				if (layer.name === action.payload.name) {
					return {
						...layer,
						visible: !layer.visible,
						is_loading: !layer.visible ? true : false,
					}
				} else if (
					(layer.is_sentinel && action.payload.is_sentinel) ||
					(layer.name.endsWith('WMS') &&
						action.payload.name.endsWith('WMS')) ||
					(layer.name === 'Parcels' &&
						action.payload.name !== 'Clouds RGB')
				) {
					return { ...layer, visible: false }
				} else {
					return layer
				}
			})
		}
		case 'CHANGE_RESOLUTION': {
			return state.map((layer) => {
				if (layer.name === 'Parcels') return { ...layer, visible: true }
				else if (
					layer.resolution &&
					layer.resolution !== action.payload.id
				)
					return { ...layer, visible: false }

				return layer
			})
		}
		case 'SET_LAYER_AVAILABILITY_DATE': {
			return state.map((layer) => {
				if (layer.name === action.payload.name)
					return { ...action.payload }
				else return layer
			})
		}
		case 'SET_LAYER_IS_LOADING': {
			return state.map((layer) => {
				if (layer.name === action.payload.name)
					return { ...action.payload }
				else return { ...layer, is_loading: false }
			})
		}
		default:
			return state
	}
}

function LayersProvider({ children }) {
	const { data: layersSeed } = useQuery({
		queryKey: ['layers'],
		queryFn: layersList,
		refetchOnWindowFocus: false,
	})

	const [layers, dispatch] = React.useReducer(layerReducer, [])
	const [selectedIndexLayer, setSelectedIndexLayer] = React.useState(null)

	React.useEffect(() => {
		if (layersSeed) {
			dispatch({ type: 'SEED_LAYER', payload: layersSeed })
		}
	}, [layersSeed])

	React.useEffect(() => {
		if (layers) {
			setSelectedIndexLayer(
				find(layers, { visible: true, is_sentinel: true })
			)
		}
	}, [layers])

	const value = {
		layers,
		dispatch,
		selectedIndexLayer,
		setSelectedIndexLayer,
	}

	return (
		<LayersContext.Provider value={value}>
			{children}
		</LayersContext.Provider>
	)
}

function useLayers() {
	const context = React.useContext(LayersContext)
	if (context === undefined) {
		throw new Error(`useLayers must be used within a LayersProvider`)
	}
	return context
}

export { LayersProvider, useLayers }
