import { useQuery, useMutation, useQueryClient } from 'react-query'
import client from './api-client'

async function signUp([
	name,
	lastName,
	email,
	password,
	profession,
	professionSpecification,
	company,
	terms_and_policy,
	confirm_invitation,
	language,
	captcha,
	promo_code,
	sign_up_event,
	campaign,
]) {
	const data = await client(`sign_up/`, {
		body: {
			name: name,
			last_name: lastName,
			email: email,
			password: password,
			profession: profession,
			professionSpecification: professionSpecification,
			company: company,
			terms_and_policy: terms_and_policy,
			confirm_invitation: confirm_invitation,
			language: language,
			captcha: captcha,
			promo_code: promo_code,
			sign_up_event: sign_up_event,
			campaign: campaign,
		},
	})
	return data
}

export function useSignUpMutation() {
	const queryClient = useQueryClient()

	return useMutation(
		(
			name,
			lastName,
			email,
			password,
			profession,
			professionSpecification,
			company,
			terms_and_policy,
			confirm_invitation,
			language,
			captcha,
			promo_code,
			sign_up_event,
			campaign
		) =>
			signUp(
				name,
				lastName,
				email,
				password,
				company,
				terms_and_policy,
				confirm_invitation,
				language,
				captcha,
				promo_code,
				sign_up_event,
				campaign
			),
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['sign_up_data', data])
			},
		}
	)
}

async function confirmSignUp([company]) {
	const data = await client(`confirm_signup/`, {
		body: { company: company },
	})
	return data
}

export function useConfirmSignUpMutation() {
	const queryClient = useQueryClient()

	return useMutation((company) => confirmSignUp(company), {
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries(['confirm_sign_up_data', data])
		},
	})
}
