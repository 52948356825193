import { useEffect } from 'react';

import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga4'

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

export const config = {
    guiOptions: {
      consentModal: {
        layout: 'box inline',
        position: 'bottom left',
      },
      preferencesModal: {
        layout: 'box',
        position: 'right',
        equalWeightButtons: true,
        flipButtons: false,
      },
    },
    categories: {
      necessary: {
        readOnly: true
      },
      functionality: {},
      analytics: {
        services: {
          ga4: {
            label:
              'Google Analytics 4',
            onAccept: () => {
              console.log('ga4 accepted');
              ReactGA.initialize(process.env.REACT_APP_GTM)
            },
            onReject: () => {
              console.log('ga4 rejected');
            },
            cookies: [
              {
                name: /^_ga/,
              },
            ],
          },
        },
      },
    },
    language: {
      default: 'es',
      autoDetect: 'browser',
      translations: {
        en: {
          consentModal: {
            description:
              'By clicking Accept all cookies, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. To manage your cookie settings, click Manage cookies.',
            acceptAllBtn: 'Accept all cookies',
            acceptNecessaryBtn: 'Reject all',
            showPreferencesBtn: 'Manage cookies',
            footer:
              '<a href="https://graniot.com/politica-de-privacidad/" target="_blank">Privacy Policy</a>\n<a href="https://graniot.com/terminos/" target="_blank">Terms and conditions</a>',
          },
          preferencesModal: {
            title: 'Consent Preferences Center',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            savePreferencesBtn: 'Save preferences',
            closeIconLabel: 'Close modal',
            serviceCounterLabel: 'Service|Services',
            sections: [
              {
                title: 'Cookie settings',
                description:
                  'When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.',
              },
              {
                title: 'Analytics/performance Cookies',
                description:
                  'These cookies enable the website to provide enhanced functionality and personalization, and allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. These cookies may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly and we will not know how you are using our site.',
                linkedCategory: 'analytics',
              },
              {
                title:
                  'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                description:
                  'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.',
                linkedCategory: 'necessary',
              },
              {
                title: 'More information',
                description:
                  'For any query in relation to my policy on cookies and your choices, please <a href="mailto:info@graniot.com">contact me</a>.',
              }
            ],
          },
        },
        es: {
            consentModal: {
              description:
                'Al hacer clic en Aceptar todas las cookies, aceptas el almacenamiento de cookies en tu dispositivo para mejorar la navegación del sitio, analizar el uso del mismo y colaborar con nuestros esfuerzos de marketing. Para gestionar tus ajustes de cookies, haz clic en Administrar cookies.',
              acceptAllBtn: 'Aceptar todas las cookies',
              acceptNecessaryBtn: 'Rechazar todas',
              showPreferencesBtn: 'Administrar cookies',
              footer:
                '<a href="https://graniot.com/politica-de-privacidad/" target="_blank">Política de privacidad</a>\n<a href="https://graniot.com/terminos/" target="_blank">Términos y condiciones</a>',
            },
            preferencesModal: {
              title: 'Centro de Preferencias de Consentimiento',
              acceptAllBtn: 'Aceptar todas',
              acceptNecessaryBtn: 'Rechazar todas',
              savePreferencesBtn: 'Guardar preferencias',
              closeIconLabel: 'Cerrar modal',
              serviceCounterLabel: 'Servicio|Servicios',
              sections: [
                {
                  title: 'Configuración de cookies',
                  description:
                    'Cuando visitas cualquier sitio web, este puede almacenar o recuperar información en tu navegador, principalmente en forma de cookies. Esta información podría ser sobre ti, tus preferencias o tu dispositivo y se utiliza principalmente para que el sitio funcione como esperas. Esta información generalmente no te identifica directamente, pero puede ofrecerte una experiencia web más personalizada. Como respetamos tu derecho a la privacidad, puedes optar por no permitir algunos tipos de cookies. Haz clic en los encabezados de las diferentes categorías para obtener más información y cambiar nuestras configuraciones predeterminadas. Sin embargo, bloquear algunos tipos de cookies puede afectar tu experiencia en el sitio y los servicios que podemos ofrecer.',
                },
                {
                  title: 'Cookies de análisis/desempeño',
                  description:
                    'Estas cookies permiten que el sitio web ofrezca funcionalidades mejoradas y personalización, y nos permiten contar las visitas y fuentes de tráfico para que podamos medir y mejorar el desempeño de nuestro sitio. Nos ayudan a saber qué páginas son las más y las menos populares y a ver cómo se mueven los visitantes por el sitio. Estas cookies pueden ser configuradas por nosotros o por proveedores externos cuyos servicios hemos añadido a nuestras páginas. Si no permites estas cookies, algunos o todos estos servicios pueden no funcionar correctamente y no sabremos cómo estás utilizando nuestro sitio.',
                  linkedCategory: 'analytics',
                },
                {
                  title:
                    'Cookies estrictamente necesarias <span class="pm__badge">Siempre activadas</span>',
                  description:
                    'Estas cookies son necesarias para que el sitio web funcione y no pueden ser desactivadas en nuestros sistemas. Generalmente, solo se configuran en respuesta a acciones realizadas por ti que equivalen a una solicitud de servicios, como establecer tus preferencias de privacidad, iniciar sesión o completar formularios. Puedes configurar tu navegador para bloquear o alertarte sobre estas cookies, pero algunas partes del sitio no funcionarán. Estas cookies no almacenan información personalmente identificable.',
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Más información',
                  description:
                    'Para cualquier consulta relacionada con mi política de cookies y tus opciones, por favor <a href="mailto:info@graniot.com">contáctame</a>.',
                }
              ],
            },
          },
  
      },
    },
  };
  

export function useCookieConsent() {

    useEffect(() => {
        CookieConsent.run(config);
    }, []);

    return null;
}