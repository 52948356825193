
export const getUrlParamValue = (param) => {
  const url = new URL(location)
  let search = window.location.search
  let params = new URLSearchParams(search)
  return params.get(param)
}


export const getAllUrlParamValues = (param) => {
  const url = new URL(location);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const values = params.getAll(param);
  return values;
};


export const getMetadataParamValues = () => {
  let search = window.location.search;
  const params = new URLSearchParams(search);
  let paramObj = {};
  for (var value of params.keys()) {
    paramObj[value] = params.get(value);
  }
  return paramObj;
};
