import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './legend-style.css'
import './leaflet-measure.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/react'

import { AuthProvider } from '@graniot/common/context/AuthContext'
import ReactErrorBoundary from '@graniot/common/components/ReactErrorBoundary'

import i18n from './i18n' // do not remove

if (!process.env.REACT_APP_DEBUG) {
	Sentry.init({
		dsn: 'https://a8b50a8b38be8538b35b7c15e1fed8c5@o1155578.ingest.sentry.io/4505935742697472',
	})
}

const queryClient = new QueryClient()

ReactDOM.render(
	<React.StrictMode>
		<ReactErrorBoundary>
			{/* Provide the structures to communicate with backend */}
			<QueryClientProvider client={queryClient}>
				{/* Provide all data on user and auth token */}
				<AuthProvider>
					<App />
				</AuthProvider>

				{process.env.REACT_APP_DEBUG && (
					<ReactQueryDevtools initialIsOpen />
				)}
			</QueryClientProvider>
		</ReactErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
